import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';

import { I18nText } from '../../../../../types';

import {
  ProductClientID,
  ProductsFiltersNavBasePath
} from '../../../../products/productsTypes';

import { FetchProductCategoriesQueryResponse } from '../../../../products/queries/fetchProductCategories.query';

import { useFinProductCategories } from '../../../../products/hooks/useFinProductCategories';

import { ThreeDStockIndexPageProductsCategoriesNavBody } from './components/ThreeDStockIndexPageProductsCategoriesNavBody';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { getProductClientIdsCacheKeyPart } from '../../../../products/utils/getProductClientIdsCacheKeyPart';

import { ProductCache } from '../../../../products/ProductCache';

interface ThreeDStockIndexPageProductsCategoriesNavProps {
  clientIds?: ProductClientID[];
  filtersNavBasePath: ProductsFiltersNavBasePath;
  allCustomI18nText?: I18nText;
}

function ThreeDStockIndexPageProductsCategoriesNav({
  clientIds,
  filtersNavBasePath,
  allCustomI18nText
}: ThreeDStockIndexPageProductsCategoriesNavProps) {
  const cacheKey = isEmpty(clientIds)
    ? ProductCache.categoriesCacheKey()
    : ProductCache.categoriesLibraryCacheKey(
        getProductClientIdsCacheKeyPart({ clientIds })
      );

  const {
    productCategories,
    productCategoriesErrorMessage,
    productCategoriesFetched,
    productCategoriesIsPlaceholderData
  } = useFinProductCategories({
    cacheKey: cacheKey,
    facilityGroupsByProduct: {
      clientIds
    }
  });

  const productCategoriesWithParents = useMemo<
    FetchProductCategoriesQueryResponse[]
  >(() => {
    const parentCategories = compact(
      productCategories.map((category) => category.parent)
    );

    return uniqBy([...productCategories, ...parentCategories], 'id');
  }, [productCategories]);

  return (
    <div className="p-4">
      <AlertMessage message={productCategoriesErrorMessage} />
      <LoadingSkeleton
        loaded={productCategoriesIsPlaceholderData || productCategoriesFetched}
      >
        <ThreeDStockIndexPageProductsCategoriesNavBody
          productCategories={productCategoriesWithParents}
          filtersNavBasePath={filtersNavBasePath}
          allCustomI18nText={allCustomI18nText}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ThreeDStockIndexPageProductsCategoriesNav;
