import React, { Fragment } from 'react';

import { I18nText } from '../../../../../../../types';

import { LifestylesFiltersNavBasePath } from '../../../../../../lifestyles/lifestylesTypes';

import { ThreeDStockIndexPageLifestylesCategoriesNavCategory } from '../../ThreeDStockIndexPageLifestylesCategoriesNav.types';

import { ThreeDStockIndexPageLifestylesCategoriesNavHead } from '../ThreeDStockIndexPageLifestylesCategoriesNavHead';
import { ThreeDStockIndexPageLifestylesCategoriesNavList } from '../ThreeDStockIndexPageLifestylesCategoriesNavList';

interface ThreeDStockIndexPageLifestylesCategoriesNavBodyProps {
  lifestyleCategories: ThreeDStockIndexPageLifestylesCategoriesNavCategory[];
  filtersNavBasePath: LifestylesFiltersNavBasePath;
  allCustomI18nText?: I18nText;
}

function ThreeDStockIndexPageLifestylesCategoriesNavBody({
  lifestyleCategories,
  filtersNavBasePath,
  allCustomI18nText
}: ThreeDStockIndexPageLifestylesCategoriesNavBodyProps) {
  return (
    <Fragment>
      <ThreeDStockIndexPageLifestylesCategoriesNavHead />
      <ThreeDStockIndexPageLifestylesCategoriesNavList
        lifestyleCategories={lifestyleCategories}
        filtersNavBasePath={filtersNavBasePath}
        allCustomI18nText={allCustomI18nText}
      />
    </Fragment>
  );
}

export default ThreeDStockIndexPageLifestylesCategoriesNavBody;
