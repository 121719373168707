import React, { useMemo, useState } from 'react';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import size from 'lodash/size';
import take from 'lodash/take';

import { I18nText } from '../../../../../../../types';

import { ProductsFiltersNavBasePath } from '../../../../../../products/productsTypes';

import { ThreeDStockIndexPageProductsCategoriesNavCategory } from '../../ThreeDStockIndexPageProductsCategoriesNav.types';

import { ThreeDStockIndexPageProductsCategoriesNavItem } from '../ThreeDStockIndexPageProductsCategoriesNavItem';
import { ThreeDStockIndexPageProductsCategoriesNavAmountButton } from '../ThreeDStockIndexPageProductsCategoriesNavAmountButton';

import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';

import { productsKeys } from '../../../../../../../locales/keys';

interface ThreeDStockIndexPageProductsCategoriesNavListProps {
  productCategories: ThreeDStockIndexPageProductsCategoriesNavCategory[];
  filtersNavBasePath: ProductsFiltersNavBasePath;
  allCustomI18nText?: I18nText;
}

function ThreeDStockIndexPageProductsCategoriesNavList({
  productCategories,
  filtersNavBasePath,
  allCustomI18nText
}: ThreeDStockIndexPageProductsCategoriesNavListProps) {
  const listCategories = useMemo<
    ThreeDStockIndexPageProductsCategoriesNavCategory[]
  >(
    () =>
      sortBy(
        filter(productCategories, (category) => !category.parent),
        'localizedName'
      ),

    [productCategories]
  );

  const [showAll, setShowAll] = useState(false);

  const allProducts = (
    <div className="-mx-4">
      <div className="flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20">
        <NextPureLinkHelper
          href={filtersNavBasePath}
          className="focus:outline-none flex-1 px-2 text-sm"
          i18nText={allCustomI18nText || productsKeys.all}
        />
      </div>
    </div>
  );

  if (size(listCategories) <= 5) {
    return (
      <>
        {allProducts}
        {listCategories.map((category) => (
          <ThreeDStockIndexPageProductsCategoriesNavItem
            key={category.id}
            category={category}
            categories={productCategories}
            filtersNavBasePath={filtersNavBasePath}
          />
        ))}
      </>
    );
  }

  const showedCategories = showAll ? listCategories : take(listCategories, 5);

  return (
    <>
      {allProducts}
      {showedCategories.map((category) => (
        <ThreeDStockIndexPageProductsCategoriesNavItem
          key={category.id}
          category={category}
          categories={productCategories}
          filtersNavBasePath={filtersNavBasePath}
        />
      ))}
      <ThreeDStockIndexPageProductsCategoriesNavAmountButton
        showAll={showAll}
        setShowAll={setShowAll}
      />
    </>
  );
}

export default ThreeDStockIndexPageProductsCategoriesNavList;
