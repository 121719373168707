import React from 'react';

import { ThreeDStockIndexPageLibraryNavItem } from './components/ThreeDStockIndexPageLibraryNavItem';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { Translate } from '../../../../../helpers/Translate';

import { ProductPath } from '../../../../products/ProductPath';
import { LifestylePath } from '../../../../lifestyles/LifestylePath';
import { MaterialPath } from '../../../../materials/MaterialPath';

import { StockPermissions } from '../../../stockConstants';

import {
  words,
  productsKeys,
  lifestylesKeys,
  materialsKeys
} from '../../../../../locales/keys';

function ThreeDStockIndexPageLifestylesCategoriesNav() {
  return (
    <CheckPermissions
      actions={[
        StockPermissions.READ_STOCK_MENU_PRODUCTS_LIBRARY_LINK,
        StockPermissions.READ_STOCK_MENU_LIFESTYLES_LIBRARY_LINK,
        StockPermissions.READ_STOCK_MENU_MATERIALS_LIBRARY_LINK
      ]}
    >
      <div className="p-4">
        <div className="-mx-4 mb-1">
          <div className="flex items-center group relative px-4 py-2">
            <h6 className="flex-1 font-semibold uppercase px-2">
              <Translate id={words.myLibrary} />
            </h6>
          </div>
        </div>
        <CheckPermissions
          action={StockPermissions.READ_STOCK_MENU_PRODUCTS_LIBRARY_LINK}
        >
          <ThreeDStockIndexPageLibraryNavItem
            href={ProductPath.libraryIndex()}
            i18nText={productsKeys.my}
          />
        </CheckPermissions>
        <CheckPermissions
          action={StockPermissions.READ_STOCK_MENU_LIFESTYLES_LIBRARY_LINK}
        >
          <ThreeDStockIndexPageLibraryNavItem
            href={LifestylePath.libraryIndex()}
            i18nText={lifestylesKeys.my}
          />
        </CheckPermissions>
        <CheckPermissions
          action={StockPermissions.READ_STOCK_MENU_MATERIALS_LIBRARY_LINK}
        >
          <ThreeDStockIndexPageLibraryNavItem
            href={MaterialPath.libraryIndex()}
            i18nText={materialsKeys.my}
          />
        </CheckPermissions>
      </div>
    </CheckPermissions>
  );
}

export default ThreeDStockIndexPageLifestylesCategoriesNav;
