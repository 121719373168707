import React, { Fragment } from 'react';

import { I18nText } from '../../../../../../../types';

import { MaterialsFiltersNavBasePath } from '../../../../../../materials/materialsTypes';

import { ThreeDStockIndexPageMaterialsCategoriesNavCategory } from '../../ThreeDStockIndexPageMaterialsCategoriesNav.types';

import { ThreeDStockIndexPageMaterialsCategoriesNavHead } from '../ThreeDStockIndexPageMaterialsCategoriesNavHead';
import { ThreeDStockIndexPageMaterialsCategoriesNavList } from '../ThreeDStockIndexPageMaterialsCategoriesNavList';

interface ThreeDStockIndexPageMaterialsCategoriesNavBodyProps {
  materialCategories: ThreeDStockIndexPageMaterialsCategoriesNavCategory[];
  filtersNavBasePath: MaterialsFiltersNavBasePath;
  allCustomI18nText?: I18nText;
}

function ThreeDStockIndexPageMaterialsCategoriesNavBody({
  materialCategories,
  filtersNavBasePath,
  allCustomI18nText
}: ThreeDStockIndexPageMaterialsCategoriesNavBodyProps) {
  return (
    <Fragment>
      <ThreeDStockIndexPageMaterialsCategoriesNavHead />
      <ThreeDStockIndexPageMaterialsCategoriesNavList
        materialCategories={materialCategories}
        filtersNavBasePath={filtersNavBasePath}
        allCustomI18nText={allCustomI18nText}
      />
    </Fragment>
  );
}

export default ThreeDStockIndexPageMaterialsCategoriesNavBody;
