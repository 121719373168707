import React, { useMemo, useState } from 'react';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import size from 'lodash/size';
import take from 'lodash/take';

import { I18nText } from '../../../../../../../types';

import { MaterialsFiltersNavBasePath } from '../../../../../../materials/materialsTypes';

import { ThreeDStockIndexPageMaterialsCategoriesNavCategory } from '../../ThreeDStockIndexPageMaterialsCategoriesNav.types';

import { ThreeDStockIndexPageMaterialsCategoriesNavItem } from '../ThreeDStockIndexPageMaterialsCategoriesNavItem';
import { ThreeDStockIndexPageMaterialsCategoriesNavAmountButton } from '../ThreeDStockIndexPageMaterialsCategoriesNavAmountButton';

import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';

import { materialsKeys } from '../../../../../../../locales/keys';

interface ThreeDStockIndexPageMaterialsCategoriesNavListProps {
  materialCategories: ThreeDStockIndexPageMaterialsCategoriesNavCategory[];
  filtersNavBasePath: MaterialsFiltersNavBasePath;
  allCustomI18nText?: I18nText;
}

function ThreeDStockIndexPageMaterialsCategoriesNavList({
  materialCategories,
  filtersNavBasePath,
  allCustomI18nText
}: ThreeDStockIndexPageMaterialsCategoriesNavListProps) {
  const listCategories = useMemo<
    ThreeDStockIndexPageMaterialsCategoriesNavCategory[]
  >(
    () =>
      sortBy(
        filter(materialCategories, (category) => !category.parent),
        'localizedName'
      ),

    [materialCategories]
  );

  const [showAll, setShowAll] = useState(false);

  const allMaterials = (
    <div className="-mx-4">
      <div className="flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20">
        <NextPureLinkHelper
          href={filtersNavBasePath}
          className="focus:outline-none flex-1 px-2 text-sm"
          i18nText={allCustomI18nText || materialsKeys.all}
        />
      </div>
    </div>
  );

  if (size(listCategories) <= 5) {
    return (
      <>
        {allMaterials}
        {listCategories.map((category) => (
          <ThreeDStockIndexPageMaterialsCategoriesNavItem
            key={category.id}
            category={category}
            categories={materialCategories}
            filtersNavBasePath={filtersNavBasePath}
          />
        ))}
      </>
    );
  }

  const showedCategories = showAll ? listCategories : take(listCategories, 5);

  return (
    <>
      {allMaterials}
      {showedCategories.map((category) => (
        <ThreeDStockIndexPageMaterialsCategoriesNavItem
          key={category.id}
          category={category}
          categories={materialCategories}
          filtersNavBasePath={filtersNavBasePath}
        />
      ))}
      <ThreeDStockIndexPageMaterialsCategoriesNavAmountButton
        showAll={showAll}
        setShowAll={setShowAll}
      />
    </>
  );
}

export default ThreeDStockIndexPageMaterialsCategoriesNavList;
