import { IconsEnum } from '../../../../../assets/icons/types';

import { Icon } from '../../../../../helpers/Icon';
import { NextLinkHelper } from '../../../../../helpers/links/NextLinkHelper';
import { Translate } from '../../../../../helpers/Translate';

import { LibraryPath } from '../../../../libraries/LibraryPath';
import { librariesKeys } from '../../../../../locales/keys';

function MenuCreateLibraryLink() {
  return (
    <div className="p-4">
      <div className="relative -m-2 p-2 flex items-center space-x-4 rounded-xl bg-pink-500 bg-opacity-0 hover:bg-opacity-10 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100">
        <div className="shrink-0 flex items-center justify-center h-16 w-16 rounded-lg bg-pink-500">
          <Icon className="h-8 w-8 text-white" icon={IconsEnum.PLUS_OUTLINE} />
        </div>
        <h3 className="text-sm font-medium">
          <NextLinkHelper
            href={LibraryPath.create()}
            className="focus:ring-0 focus:ring-offset-0"
          >
            <span className="absolute inset-0" aria-hidden="true" />
            <Translate id={librariesKeys.createYourOwnLibrary} />
            &nbsp;
            <span aria-hidden="true">→</span>
          </NextLinkHelper>
        </h3>
      </div>
    </div>
  );
}

export default MenuCreateLibraryLink;
