import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';

import { I18nText } from '../../../../../types';

import {
  LifestyleClientID,
  LifestylesFiltersNavBasePath
} from '../../../../lifestyles/lifestylesTypes';

import { FetchLifestyleCategoriesQueryResponse } from '../../../../lifestyles/queries/fetchLifestyleCategories.query';

import { useFinLifestyleCategories } from '../../../../lifestyles/hooks/useFinLifestyleCategories';

import { ThreeDStockIndexPageLifestylesCategoriesNavBody } from './components/ThreeDStockIndexPageLifestylesCategoriesNavBody';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { getLifestyleClientIdsCacheKeyPart } from '../../../../lifestyles/utils/getLifestyleClientIdsCacheKeyPart';

import { LifestyleCache } from '../../../../lifestyles/LifestyleCache';

interface ThreeDStockIndexPageLifestylesCategoriesNavProps {
  clientIds?: LifestyleClientID[];
  filtersNavBasePath: LifestylesFiltersNavBasePath;
  allCustomI18nText?: I18nText;
}

function ThreeDStockIndexPageLifestylesCategoriesNav({
  clientIds,
  filtersNavBasePath,
  allCustomI18nText
}: ThreeDStockIndexPageLifestylesCategoriesNavProps) {
  const cacheKey = isEmpty(clientIds)
    ? LifestyleCache.categoriesCacheKey()
    : LifestyleCache.categoriesLibraryCacheKey(
        getLifestyleClientIdsCacheKeyPart({ clientIds })
      );

  const {
    lifestyleCategories,
    lifestyleCategoriesErrorMessage,
    lifestyleCategoriesFetched,
    lifestyleCategoriesIsPlaceholderData
  } = useFinLifestyleCategories({
    cacheKey: cacheKey,
    roomsByLifestyle: {
      clientIds
    }
  });

  const lifestyleCategoriesWithParents = useMemo<
    FetchLifestyleCategoriesQueryResponse[]
  >(() => {
    const parentCategories = uniqBy(
      compact(lifestyleCategories.map((category) => category.parent)),
      'id'
    );

    return sortBy(
      uniqBy([...lifestyleCategories, ...parentCategories], 'id'),
      'name'
    );
  }, [lifestyleCategories]);

  return (
    <div className="p-4">
      <AlertMessage message={lifestyleCategoriesErrorMessage} />
      <LoadingSkeleton
        loaded={
          lifestyleCategoriesIsPlaceholderData || lifestyleCategoriesFetched
        }
      >
        <ThreeDStockIndexPageLifestylesCategoriesNavBody
          lifestyleCategories={lifestyleCategoriesWithParents}
          filtersNavBasePath={filtersNavBasePath}
          allCustomI18nText={allCustomI18nText}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ThreeDStockIndexPageLifestylesCategoriesNav;
