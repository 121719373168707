import React, { Fragment } from 'react';

import { I18nText } from '../../../../../../../types';

import { ProductsFiltersNavBasePath } from '../../../../../../products/productsTypes';

import { ThreeDStockIndexPageProductsCategoriesNavCategory } from '../../ThreeDStockIndexPageProductsCategoriesNav.types';

import { ThreeDStockIndexPageProductsCategoriesNavHead } from '../ThreeDStockIndexPageProductsCategoriesNavHead';
import { ThreeDStockIndexPageProductsCategoriesNavList } from '../ThreeDStockIndexPageProductsCategoriesNavList';

interface ThreeDStockIndexPageProductsCategoriesNavBodyProps {
  productCategories: ThreeDStockIndexPageProductsCategoriesNavCategory[];
  filtersNavBasePath: ProductsFiltersNavBasePath;
  allCustomI18nText?: I18nText;
}

function ThreeDStockIndexPageProductsCategoriesNavBody({
  productCategories,
  filtersNavBasePath,
  allCustomI18nText
}: ThreeDStockIndexPageProductsCategoriesNavBodyProps) {
  return (
    <Fragment>
      <ThreeDStockIndexPageProductsCategoriesNavHead />
      <ThreeDStockIndexPageProductsCategoriesNavList
        productCategories={productCategories}
        filtersNavBasePath={filtersNavBasePath}
        allCustomI18nText={allCustomI18nText}
      />
    </Fragment>
  );
}

export default ThreeDStockIndexPageProductsCategoriesNavBody;
